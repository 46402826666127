.address-page {
  .capitalize {
    text-transform: capitalize;
  }
  height: 100vh;
  background-color: #f6f8f1;

  .content {
    max-width: 370px;

    form {
      width: 100%;
    }
  }

  h1 {
    color: var(--tertiary);
    font-size: 28px;
  }
  .ingress {
    max-width: 350px;
    text-align: center;
  }

  .lottie {
    max-width: 370px;
  }

  .aui-column {
    height: 100vh;
  }
  .aui-text {
    margin-bottom: 0px;

    input {
      background-color: white;
      border-bottom: 1px solid var(--tertiary);
      margin-top: 12px;
    }
  }

  .search-results {
    background-color: white;
    .btn-list {
      width: 100%;
      padding: 10px;
      text-align: left;
      background-color: white;
      border: 0px;
    }
  }

  .btn-link {
    margin-top: 12px;
  }
}
