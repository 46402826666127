.calc-page {
  background-image: url("/assets/solar_panel.png");
  min-height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

#root,
form {
  .btn-link {
    &.no-padding {
      padding-left: 0px;
    }

    &.inline {
      display: inline;
      margin-top: 0px;
    }
  }
}

footer {
  height: 120px;
  background-color: var(--secondary);
}
