.page {
  background-color: var(--secondary);
  padding: 49px;
  margin-bottom: 48px;
  border-radius: 16px;
  &.effect {
    .bulb {
      height: 100px;
    }
  }

  .aui-form-control {
    input[type="text"] {
      //width: 100%;
      border-bottom: 1px solid;
      margin-top: 12px;
    }
  }
}
