.calc-page {
  height: 100%;
  background-color: var(--tertiary);

  section {
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
  }

  .lottie {
    height: 200px;
  }

  .slider-container {
    max-width: 400px;
  }

  h3 {
    font-weight: 600;
    font-size: 21px;
  }
  .content {
    margin: 5vw 16px 16px 16px;
    width: 864px;
    max-width: 100vw;

    .building-type-list {
      .list-items {
        justify-content: space-between;

        img {
          margin-bottom: 12px;
        }
      }
    }

    .aui-list {
      &:not(.no-border) {
        .list-item {
          border: 1px solid;
        }
      }
    }
  }

  .field-label {
    padding: 8px;
    margin-bottom: 20px;
  }

  .flex-layout {
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;

    .flex-items {
      flex: 1;
    }
  }

  .flex-item {
    flex: 1;
  }

  .input-with-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .result {
    position: sticky;
    top: calc(72px + 5vw);
    margin: 0 16px 16px 16px;
    background-color: var(--secondary);
    margin-bottom: 48px;
    border-radius: 16px;

    width: 500px;
    max-width: 100vw;

    .result-content {
      padding: 49px;
    }

    .result-group {
      line-height: 31pt;

      .help-text {
        line-height: 18pt;
      }
    }

    .btn {
      display: block;
      margin-top: 48px;
    }

    h3 {
      display: inline;
    }

    .unit {
      font-weight: 600;
    }
  }

  .aui-integer,
  .aui-decimal {
    flex: 0 1 300px;
    input {
      min-width: 100%;
    }
  }

  footer {
    padding: 12px;
    .aui-row {
      height: 100%;
      justify-content: center;
      flex-wrap: wrap;
    }
    img {
      height: 48px;
      margin-left: 10px;
    }

    .content {
      margin-left: 10px;
    }
  }
}
