header {
  position: sticky;
  top: 0;
  z-index: 10;
  min-height: 72px;
  padding: 8px 32px;

  border-bottom: 1px solid var(--tertiary);
  img {
    height: 40px;
    padding-right: 32px;
  }
  .aui-row {
    min-height: inherit;

    flex-wrap: wrap;
    margin-right: 0px;
  }

  .aui-row > * {
    margin-right: 0px;
  }

  .logo-text {
    font-size: 28px;

    .main {
      font-weight: 600;
    }
  }

  h1 {
    .uppercase {
      text-transform: uppercase;
    }

    .regular-weight {
      font-weight: 400;
    }
  }

  background-color: var(--secondary);
  .aui-row {
    background-color: var(--secondary);

    .menu-item {
      margin-right: 27px;
    }
  }

  @media (max-width: 290px) {
    .aui-row {
      padding-left: 16px;
      padding-right: 16px;
      img {
        padding-right: 16px;
      }
    }
  }

  @media (max-width: 240px) {
    img {
      display: none;
    }
  }
}
